<template>
  <div>
    webservices
  </div>
</template>

<script>
  export default {
    name: 'WebServices'
  }
</script>

<style lang="scss" scoped>

</style>